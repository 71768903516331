import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {FacturationClientService} from "../../../../../core/services/entities/facturation-client.service";
import {FactureClientSettingsML} from "../../../../../core/dtos/facturation/clients/factures/facture-client-settings-ml";
import {Subscription} from "rxjs";
import {FactureClientDto} from "../../../../../core/dtos/facturation/clients/factures/facture-client-dto";

@Component({
  selector: 'yo-print-facture-settings',
  templateUrl: './print-facture-settings.component.html',
  styleUrls: ['./print-facture-settings.component.scss']
})

export class PrintFactureSettingsComponent implements OnInit, OnDestroy {

  constructor(public utils: UtilsService,
              private toastSvc: ToastService,
              private facturationClientSvc: FacturationClientService) {
  }

  displayDialog: boolean;
  printSettingsSub: Subscription;
  settings: FactureClientSettingsML = new FactureClientSettingsML();


  ngOnInit(): void {
    this.initSettings();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.printSettingsSub);
  }

  private initSettings() {
    this.printSettingsSub = this.facturationClientSvc.factureClientPrint$
      .subscribe((facture: FactureClientDto) => {
        this.settings.id = facture.id
        this.settings.details = !facture.pointFacturation.synthetique
        this.displayDialog = true;
      })
  }

  closeDialog() {
    this.displayDialog = false;
  }

  print() {
    this.facturationClientSvc.printFactureClient(this.settings);
    this.closeDialog()
  }

}
