<dx-popup
  [showTitle]="true"
  [title]="'Éléments déjà facturés sur la période sélectionnée'"
  [(visible)]="displayDialog">

  <dx-tree-view
    [items]="treeViewData"
    [dataStructure]="'tree'"
    displayExpr="text"
    keyExpr="id"
    parentIdExpr="parentId">
  </dx-tree-view>
</dx-popup>
