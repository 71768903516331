import {Component, OnDestroy, OnInit} from '@angular/core';
import {PointFacturationDto} from '../../../core/dtos/facturation/clients/points-facturation/point-facturation-dto';
import {
  FacturationClientService,
  PointFacturationSavedSupplier
} from '../../../core/services/entities/facturation-client.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {confirm} from "devextreme/ui/dialog";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  templateUrl: './point-facturation.component.html',
  styleUrls: ['./point-facturation.component.scss']
})
export class PointFacturationComponent implements OnInit, OnDestroy {

  pointsFacturation: PointFacturationDto[] = [];

  selectedRows: number[] = [];

  findAllSub: Subscription;
  pointFacturationSavedSub: Subscription;
  deleteSub: Subscription;

  constructor(public utils: UtilsService,
              private i8nSvc: InternationalizationService,
              public toastSvc: ToastService,
              public facturationClientSvc: FacturationClientService) {
  }


  ngOnInit(): void {
    this.initData();
    this.initPointFacturationSavedSubscription();
  }

  initData(): void {
    this.findAllSub = this.facturationClientSvc.findAllPointsFacturation().subscribe(response => {
      this.pointsFacturation = response.allPointsFacturation;
    });
  }

  initPointFacturationSavedSubscription = (): void => {
    this.pointFacturationSavedSub = this.facturationClientSvc.pointFacturationSaved$
      .subscribe((response: PointFacturationSavedSupplier) => {
        if (response.isUpdate) {
          const idx: number = this.pointsFacturation.findIndex(PointFacturation => PointFacturation.id === response.pointFacturation.id);
          this.pointsFacturation[idx] = response.pointFacturation;
        } else {
          this.pointsFacturation.push(response.pointFacturation);
        }
      });
  };

  openDialogEdition = (pointFacturation: PointFacturationDto): void => {
    this.facturationClientSvc.announceOpenDialogEditionPointFacturation(pointFacturation);
  };

  deleteValues = async (): Promise<void> => {

    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    const isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? 'CONFIRM_DELETION_PLURAL' : 'CONFIRM_DELETION_SINGULAR', null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.deleteSub = this.facturationClientSvc.deletePointFacturation(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length > 0) {
          for (const error of res.messagesErrorList) {
            const elementToKeep = this.pointsFacturation.find(pointFacturation => pointFacturation.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `DELETION_COMPLETED_PLURAL` : `DELETION_COMPLETED_SINGULAR`, null));
        }
        this.pointsFacturation = this.pointsFacturation.filter(pointFacturation => !idsToDeleteInTable.find(id => id === pointFacturation.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.pointFacturationSavedSub);
    this.utils.unsubscribe(this.findAllSub);
    this.utils.unsubscribe(this.deleteSub);
  }
}
