import {Injectable} from "@angular/core";
import {UtilsService} from "../../utils/utils.service";
import {Auth2Service} from "../security/auth2.service";
import {HttpClient} from "@angular/common/http";
import {HttpService} from "../technique/http.service";
import {Observable, Subject} from "rxjs";
import {ElementFacturableDTO} from "../../dtos/ElementFacturableDTO";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ObjectDTO} from "../../dtos/object-dto";

@Injectable({providedIn: 'root'})
export class ElementFacturableService {

  public noImagePath: string = 'assets/images/no-image.png';

  private subjectElementSaved = new Subject<ElementFacturableSavedSupplier>();
  elementSaved$ = this.subjectElementSaved.asObservable();

  private subjectOpenDialog = new Subject<ElementFacturableDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  constructor(private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private http: HttpClient,
              private httpSvc: HttpService
  ) {}

  announceOpenDialogEdition = (element: ElementFacturableDTO) => {
    this.subjectOpenDialog.next(element);
  };

  save = (elementFact: ElementFacturableDTO): Observable<ResponseWrapper<ElementFacturableDTO>> => {
    return this.httpSvc.post("dolrest/referentiel/facturation/element-facturable/save", elementFact);
  }

  announceElementFacturableSaved = (element: ElementFacturableDTO, isUpdate: boolean = false) => {
    this.subjectElementSaved.next({isUpdate, elementFacturable: element} as ElementFacturableSavedSupplier);
  };

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  deleteElementFacturablesByFilters(selectedRows: number[]) {
    let params: string = '1=1';
    if (selectedRows && selectedRows.length) params += `&ids=${selectedRows.join(',')}`
    return this.http.delete(`dolrest/referentiel/facturation/element-facturable/delete?${params}`);
  }
}

export interface ElementFacturableSavedSupplier {
  isUpdate: boolean;
  elementFacturable: ElementFacturableDTO;
}
