import {ObjectDTO} from '../../../object-dto';
import {PrixDto} from '../../../prix-dto';

export class LigneFactureClientDto extends ObjectDTO {

  idFactureClient: number;
  libelleElementFacturable: string;
  codeElementFacturable: string;
  libellePrestation: string;
  libellePlc: string;
  codePlc: string;
  libelleRegime: string;
  libelleRepas: string;
  quantite: number;
  montantUnitaire: PrixDto;
  montantHT: PrixDto;
  montantTTC: PrixDto;
  tauxTVA: number;

  public static getAllProperties = (): String => {
    return `
      id,
      libelleElementFacturable,
      codeElementFacturable,
      libellePrestation,
      libellePlc,
      codePlc,
      libelleRegime,
      libelleRepas,
      quantite,
      montantUnitaire {
        value,
        uniteMonetaire
      },
      montantHT {
        value,
        uniteMonetaire
      },
      montantTTC {
        value,
        uniteMonetaire
      },
      tauxTVA
    `
  }
}
