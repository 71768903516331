import {Component, OnDestroy, OnInit} from "@angular/core";
import {FactureClientDto} from "../../../../core/dtos/facturation/clients/factures/facture-client-dto";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {
  FacturationClientService,
  GenerateFactureClientSupplier
} from "../../../../core/services/entities/facturation-client.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {GenerateFactureClientDto} from "../../../../core/dtos/facturation/clients/factures/generate-facture-client-dto";

@Component({
  selector: 'yo-facture-client-details-dialog',
  templateUrl: './facture-client-details-dialog.component.html',
  styleUrls: ['./facture-client-details-dialog.component.scss']
})
export class FactureClientDetailsDialogComponent implements OnInit, OnDestroy {

  dialogTitle: string;
  factureClient: FactureClientDto;
  generateFactureClient: GenerateFactureClientDto;
  displayDialog: boolean = false;
  fullScreenDialog: boolean = true;
  openDialogSub: Subscription;

  constructor(public utilsSvc: UtilsService,
              public auth2Svc: Auth2Service,
              public toastSvc: ToastService,
              private facturationClientSvc: FacturationClientService) {
  }

  ngOnInit(): void {
    this.openDialogDetailsSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.openDialogSub)
  }

  openDialogDetailsSubscription = (): void => {
    this.openDialogSub = this.facturationClientSvc.openDialogDetailsFactureClient$
      .subscribe((res: GenerateFactureClientSupplier) => {
        this.dialogTitle = 'Détails de la facture'
        this.displayDialog = true;
        this.factureClient = res.factureClient;
        this.generateFactureClient = res.generateFactureClient;
      });
  };

  generateFacture() {
    this.facturationClientSvc.generateFactureClient(this.generateFactureClient).subscribe((response) => {
      if (!this.utilsSvc.isResponseSupplierError(response)) {
        this.facturationClientSvc.announceFactureClientCreated(response.one);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La facture a été créée avec succès`);
      }
      this.closeDialog();
    })
  }

  printFacture() {
    this.facturationClientSvc.announceOpenPrintSettings(this.factureClient);
  }

  toggleFullScreenInvoice = (): void => {
    this.fullScreenDialog = !this.fullScreenDialog;
  }

  closeDialog() {
    this.displayDialog = false;
  }
}
