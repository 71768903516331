
export class LigneParametrageFacturation {
  id: number;

  siteId: number;
  siteLibelle: string;

  offreAlimentaireId: number;
  offreAlimentaireLibelle: string;

  prestationId: number;
  prestationLibelle: string;

  repasId: number;
  repasLibelle: string;

  regimeId: number;
  regimeLibelle: string;

  pointFacturationId: number;
  pointFacturationLibelle: string;

  pointLivraisonId: number;
  pointLivraisonLibelle: string;

  elementFacturableId: number;
  elementFacturableLibelle: string;

  taxeId: number;
  taxeLibelle: string;

  prixVenteHT: number;

  static getAllGraphQlProperties() {
    return `
    id,
    siteId,
    siteLibelle,
    offreAlimentaireId,
    offreAlimentaireLibelle,
    prestationId,
    prestationLibelle,
    repasId,
    repasLibelle,
    regimeId,
    regimeLibelle,
    pointFacturationId,
    pointFacturationLibelle,
    pointLivraisonId,
    pointLivraisonLibelle,
    elementFacturableId,
    elementFacturableLibelle,
    taxeId,
    taxeLibelle,
    prixVenteHT
    `
  }
}
