<div class="custom-toolbar">
  <div class="custom-button-container-left">
    <yo-button
      label="CRÉER"
      tooltip="Créer un nouveau paramétrage"
      iconClass="fa fa-plus"
      (onClick)="expandAddArea()">
    </yo-button>
  </div>
  <div class="custom-toolbar-element custom-button-container-right">
    <yo-button
      tooltip="Modifier les lignes de paramétrage sélectionnées"
      iconClass="fa fa-edit"
      [disabled]="!isRowSelected()"
      (onClick)="openModificationPopup()">
    </yo-button>
    <yo-button
      iconClass="fa fa-save"
      tooltip="Enregistrer les lignes de paramétrage"
      buttonClass="cta-success"
      [disabled]="!gridChanges.hasChanges()"
      (onClick)="save()">
    </yo-button>
    <yo-button
      iconClass="fa fa-trash"
      tooltip="Supprimer les lignes de paramétrage sélectionnées"
      buttonClass="cta-inner-delete"
      [disabled]="!isRowSelected()"
      (onClick)="delete()">
    </yo-button>
  </div>
</div>
<div id="adding-area">
  <div id="adding-area-container">
    <div id="adding-area-content" class="border-like-dx {{isAddAreaCollapsed ? 'collapsed' : ''}}">
      <div class="line">
        <yo-tagbox
          [data]="hierarchy?.offresAlimentaire"
          [selectedItems]="selectedOffreAlimentaireList"
          (onValueChange)="updateValue($event, cellTypes.OFFRE_ALIMENTAIRE)"
          placeholder="Offre alimentaire">
        </yo-tagbox>
        <yo-tagbox
          [data]="prestationList"
          [selectedItems]="selectedPrestationList"
          (onValueChange)="updateValue($event, cellTypes.PRESTATION)"
          [disabled]="!isParentSelected(selectedOffreAlimentaireList)"
          placeholder="Prestation">
        </yo-tagbox>
        <yo-tagbox
          [data]="repasList"
          [selectedItems]="selectedRepasList"
          (onValueChange)="updateValue($event, cellTypes.REPAS)"
          [disabled]="!isParentSelected(selectedPrestationList)"
          placeholder="Repas">
        </yo-tagbox>
      </div>
      <div class="line">
        <yo-tagbox
          [data]="regimeList"
          [selectedItems]="selectedRegimeList"
          (onValueChange)="updateValue($event, cellTypes.REGIME)"
          [disabled]="!isParentSelected(selectedPrestationList)"
          placeholder="Régime">
        </yo-tagbox>
        <yo-tagbox
          [data]="pointFacturationList"
          [selectedItems]="selectedPointFacturationList"
          (onValueChange)="updateValue($event, cellTypes.POINT_FACTURATION)"
          [disabled]="!isParentSelected(selectedPrestationList)"
          placeholder="Point de facturation">
        </yo-tagbox>
        <yo-tagbox
          [data]="pointLivraisonList"
          [selectedItems]="selectedPointLivraisonList"
          (onValueChange)="updateValue($event, cellTypes.POINT_LIVRAISON)"
          [disabled]="!isParentSelected(selectedPointFacturationList)"
          placeholder="Point de livraison">
        </yo-tagbox>
      </div>
      <div class="line custom-button-container-right">
        <yo-button
          iconClass="fa fa-save"
          label="Ajouter"
          tooltip="Ajouter les lignes de paramétrage"
          buttonClass="cta-success"
          [disabled]="!isReadyToAdd()"
          (onClick)="addLignesParametrage()">
        </yo-button>
        <yo-button
          iconClass="fa fa-times"
          tooltip="Réduire la zone d'ajout de lignes de paramétrage"
          label="Fermer"
          buttonClass="cta-inner-delete"
          (onClick)="reduceAddArea()">
        </yo-button>
      </div>
    </div>
  </div>
</div>
<div class="grid-container">
  <dx-data-grid
    #grid
    [dataSource]="ligneParametrageList"
    keyExpr="id"
    noDataText="Pas de données"
    showBorders="true"
    showRowLines="true"
    allowColumnResizing="true"
    rowAlternationEnabled="true"
    columnResizingMode="widget">

    <dxo-selection
      selectAllMode="allPages"
      showCheckBoxesMode="always"
      mode="multiple">
    </dxo-selection>

    <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} ligne(s) de paramétrage">
    </dxo-pager>

    <dxi-column
      caption="Point de facturation"
      dataField="pointFacturationLibelle">
    </dxi-column>
    <dxi-column
      caption="Point de livraison"
      dataField="pointLivraisonLibelle">
    </dxi-column>
    <dxi-column
      caption="Offre alimentaire"
      dataField="offreAlimentaireLibelle">
    </dxi-column>
    <dxi-column
      caption="Prestation"
      dataField="prestationLibelle">
    </dxi-column>
    <dxi-column
      caption="Repas"
      dataField="repasLibelle">
    </dxi-column>
    <dxi-column
      caption="Régime"
      dataField="regimeLibelle">
    </dxi-column>
    <dxi-column
      caption="Élément facturable"
      dataField="elementFacturableLibelle">
    </dxi-column>
    <dxi-column
      caption="Taxe"
      dataField="taxeLibelle">
    </dxi-column>
    <dxi-column
      caption="Prix de vente HT"
      dataField="prixVenteHT"
      alignment="right"
      cellTemplate="prixTemplate">
    </dxi-column>

    <div *dxTemplate="let cell of 'prixTemplate'" class="prix-container">
      {{ cell?.row?.data?.prixVenteHT ? cell.row.data.prixVenteHT.toFixed(3) : 0 }} €
    </div>
  </dx-data-grid>
</div>

<dx-popup
  title="Modification de masse"
  [visible]="isModificationPopupDisplayed"
  width="auto"
  height="auto"
  minWidth="600"
  (onHiding)="closeModificationPopup()">
  <div class="dialog-line">
    <div class="label">Élément facturable</div>
    <div class="value">
      <yo-lookup
        [data]="elementFacturableList"
        [selectedItem]="selectedElementFacturable"
        autoSelect="false"
        (onValueChange)="updateElementFacturable($event)"
        placeholder="Sélectionner l'élément facturable ...">
      </yo-lookup>
    </div>
  </div>
  <div class="dialog-line">
    <div class="label">Taxe</div>
    <div class="value">
      <yo-lookup
        [data]="taxeList"
        [selectedItem]="selectedTaxe"
        autoSelect="false"
        (onValueChange)="updateTaxe($event)"
        placeholder="Sélectionner la taxe ...">
      </yo-lookup>
    </div>
  </div>
  <div class="dialog-line">
    <div class="label">Prix</div>
    <div class="value">
      <dx-number-box
        [(value)]="selectedPrix">
      </dx-number-box>
    </div>
  </div>
  <div class="dialog-line">
    <div class="custom-button-container-right">
      <yo-button
        iconClass="fa fa-check"
        buttonClass="cta-success"
        (onClick)="applyModifications()">
      </yo-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  title="Paramétrage des éléments à facturer"
  [visible]="isEditLinesPopupDisplayed"
  width="1200"
  height="700"
  minWidth="600"
  resizeEnabled="true"
  (onHiding)="closeEditLinesPopup()">
  <div id="popup-grid-container" class="custom-container">
    <div class="custom-button-container-right">
      <yo-button
        tooltip="Modifier les lignes de paramétrage sélectionnées"
        iconClass="fa fa-edit"
        [disabled]="!isPopupRowSelected()"
        (onClick)="openModificationPopup()">
      </yo-button>
      <yo-button
        iconClass="fa fa-save"
        tooltip="Enregistrer les lignes de paramétrage"
        buttonClass="cta-success"
        [disabled]="!canAddValues()"
        (onClick)="saveEditedLines()">
      </yo-button>
    </div>

    <div class="grid-container">
      <dx-data-grid
        #gridEdit
        [dataSource]="ligneToAddList"
        keyExpr="id"
        noDataText="Pas de données"
        showBorders="true"
        showRowLines="true"
        allowColumnResizing="true"
        rowAlternationEnabled="true"
        columnResizingMode="widget">

        <dxo-selection
          selectAllMode="allPages"
          showCheckBoxesMode="always"
          mode="multiple">
        </dxo-selection>

        <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
        <dxo-header-filter [visible]="true"></dxo-header-filter>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} ligne(s) de paramétrage">
        </dxo-pager>

        <dxi-column
          caption="Point de facturation"
          dataField="pointFacturationLibelle">
        </dxi-column>
        <dxi-column
          caption="Point de livraison"
          dataField="pointLivraisonLibelle">
        </dxi-column>
        <dxi-column
          caption="Offre alimentaire"
          dataField="offreAlimentaireLibelle">
        </dxi-column>
        <dxi-column
          caption="Prestation"
          dataField="prestationLibelle">
        </dxi-column>
        <dxi-column
          caption="Repas"
          dataField="repasLibelle">
        </dxi-column>
        <dxi-column
          caption="Régime"
          dataField="regimeLibelle">
        </dxi-column>
        <dxi-column
          caption="Élément facturable"
          dataField="elementFacturableLibelle">
        </dxi-column>
        <dxi-column
          caption="Taxe"
          dataField="taxeLibelle">
        </dxi-column>
        <dxi-column
          caption="Prix de vente HT"
          dataField="prixVenteHT"
          alignment="right"
          cellTemplate="prixTemplate">
        </dxi-column>

        <div *dxTemplate="let cell of 'prixTemplate'" class="prix-container">
          {{ cell?.row?.data?.prixVenteHT ? cell.row.data.prixVenteHT.toFixed(3) : 0 }} €
        </div>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>

