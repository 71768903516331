import {Component, OnDestroy, OnInit} from "@angular/core";
import {FacturationClientService} from "../../../../core/services/entities/facturation-client.service";
import {FactureClientErrorsDto} from "../../../../core/dtos/facturation/clients/factures/facture-client-errors-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'yo-facture-client-errors',
  templateUrl: './facture-client-errors.component.html',
  styleUrls: ['./facture-client-errors.component.scss']
})
export class FactureClientErrorsComponent implements OnInit, OnDestroy {

  displayDialog: boolean = false;
  treeViewData: any[] = [];
  errorSub: Subscription;

  constructor(private factureClientSvc: FacturationClientService,
              private utilsSvc: UtilsService) {
  }

  ngOnInit(): void {
    this.errorSub = this.factureClientSvc.factureClientErrors$.subscribe((errors) => {
      this.displayDialog = true
      this.formateErrorsData(errors)
    })
  }

  formateErrorsData(errors: [FactureClientErrorsDto]): void {
    this.treeViewData = errors.map(factureInError => ({
      id: `PF-${factureInError.idPointFacturation}`,
      text: `Point de facturation : ${factureInError.libellePointFacturation}`,
      expanded: true,
      items: factureInError.existingFactureClientForPeriode.map(item => ({
        id: `LF-${item.id}`,
        text: `Facture n° ${item.numeroFacture} du ${this.utilsSvc.dateToDDMMYYYY(item.dateDebut)} au ${this.utilsSvc.dateToDDMMYYYY(item.dateFin)} - ${this.printFactureClientErrorText(factureInError)}`,
        expanded: true,
        items: factureInError.isFactureClientIdentical ? [] : item.ligneFactureClientList.map(subItem => ({
          id: subItem.id,
          text: `${subItem.libellePlc}, ${subItem.libelleElementFacturable}, ${subItem.libellePrestation}, ${subItem.libelleRepas}, ${subItem.libelleRegime}`
        }))
      }))
    }));
  }

  printFactureClientErrorText(factureInError: FactureClientErrorsDto): string {
    return factureInError.isFactureClientIdentical ?
       `Identique` : `les éléments déjà facturés ci-dessous ne seront pas présents sur la facture`;
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.errorSub);
  }

}
