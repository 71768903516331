import {ObjectDTO} from '../../../object-dto';
import {PointFacturationDto} from '../points-facturation/point-facturation-dto';
import {AdresseDTO} from '../../../adresse-dto';
import {LigneFactureClientDto} from './ligne-facture-client-dto';
import {PrixDto} from '../../../prix-dto';


export class FactureClientDto extends ObjectDTO {

  pointFacturation: PointFacturationDto;
  statut: string;
  adressePointFacturation: AdresseDTO;
  adresseUdp: AdresseDTO;
  dateFacturation: Date;
  dateEcheance: Date;
  dateDebut: Date;
  dateFin: Date;
  numeroFacture: string;
  montantHT: PrixDto;
  montantTTC: PrixDto;
  quantite: number;
  ligneFactureClientList: LigneFactureClientDto[];

  constructor() {
    super();
    this.id = 0;
  }

  public static getAllProperties = (): String => {
    return `
      id,
      site {
        id,
        libelle
      },
      statut,
      pointFacturation {
        id,
        libelle
      },
      adressePointFacturation {
        id,
        nom,
        ville,
        adresse1,
        adresse2,
        adresse3,
        telephone,
        fax,
        codePostal,
        ville,
        email
      },
      adresseUdp {
        id,
        nom,
        ville,
        adresse1,
        adresse2,
        adresse3,
        telephone,
        fax,
        codePostal,
        ville,
        email
      },
      dateFacturation,
      dateEcheance,
      dateDebut,
      dateFin,
      numeroFacture,
      montantHT {
        value,
        uniteMonetaire
      },
      montantTTC {
        value,
        uniteMonetaire
      },
      quantite,
      ligneFactureClientList {
        ${LigneFactureClientDto.getAllProperties()}
      }
    `
  }
}
