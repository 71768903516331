import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {confirm} from "devextreme/ui/dialog";

import {Subscription} from "rxjs";

import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ElementFacturableDTO} from "../../../core/dtos/ElementFacturableDTO";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {
  ElementFacturableSavedSupplier,
  ElementFacturableService
} from "../../../core/services/entities/element-facturable.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {FacturationClientService} from "../../../core/services/entities/facturation-client.service";

@Component({
  selector: 'yo-elementFacturable',
  templateUrl: './element-facturable.component.html',
  styleUrls: ['./element-facturable.component.scss']
})
export class ElementFacturableComponent implements OnInit, OnDestroy {

  subElementsFacturables: Subscription;
  subUpdateElementsFacturables: Subscription
  elementsFacturables: ElementFacturableDTO[] = [];
  deleteSub: Subscription;
  displayDeletePopupErrors: boolean;

  selectedRows: number[] = [];
  errors: any[] = [];

  pathHelpFile: string = null;// HELP_FOLDERS.REFERENTIEL + '/elementFacturable/elementFacturable';


  constructor(public elementFacturableSvc: ElementFacturableService,
              private auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              public utilsSvc: UtilsService,
              private graphQlSvc: GraphQLService,
              private factureSvc: FacturationClientService,
              public toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initElementsFacturable();
    this.updateElementsFacturables();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subElementsFacturables);
    this.utilsSvc.unsubscribe(this.subUpdateElementsFacturables);
  }

  initElementsFacturable = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subElementsFacturables = this.graphQlSvc.sendQuery(`
      {
        allElementsFacturables(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            code,
            libelle,
            taxe {
              libelle,
              actif,
              valeur
            },
            site {
                id,
                libelle,
            },
        }
      }
    `)
      .subscribe((response: any) => {
        this.elementsFacturables = response.allElementsFacturables;
      });
  }

  updateElementsFacturables = (): void => {
    this.subUpdateElementsFacturables = this.elementFacturableSvc.elementSaved$
      .subscribe((response: ElementFacturableSavedSupplier) => {
        if (response.isUpdate) {
          const idx: number = this.elementsFacturables.findIndex(elementFacturable => elementFacturable.id === response.elementFacturable.id);
          this.elementsFacturables[idx] = response.elementFacturable;
        } else {
          this.elementsFacturables.push(response.elementFacturable);
        }
      });
  };

  openDialogEdition = (elementFacturable: ElementFacturableDTO): void => {
    this.elementFacturableSvc.announceOpenDialogEdition(elementFacturable);
  };

  canDelete = (): boolean => this.selectedRows.length > 0;

  calculateSiteValue = (element: ElementFacturableDTO): String => {
    return element?.site?.libelle
  };

  calculateTaxeValue = (element: ElementFacturableDTO) : String =>  {
    return element.taxe.libelle + ' ' + element?.taxe?.valeur
  }

  closeErrors() {
    this.displayDeletePopupErrors = false;
  }

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.deleteSub = this.elementFacturableSvc.deleteElementFacturablesByFilters(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res.messagesErrorList && res.messagesErrorList.length > 0) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.elementsFacturables.find(allergene => allergene.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `DELETION_COMPLETED_PLURAL` : `DELETION_COMPLETED_SINGULAR`, null));
        }
        this.elementsFacturables = this.elementsFacturables.filter(allergene => !idsToDeleteInTable.find(id => id === allergene.id));
      }
    });
  }

  private displayDeleteErrors() {
      this.displayDeletePopupErrors = true;
  }
}
