import {ObjectDTO} from "./object-dto";
import {TaxeDTO} from "./taxe-dto";
import {SiteDTO} from "./site-dto";

export class ElementFacturableDTO  extends ObjectDTO{

  taxe: TaxeDTO;
  code: string;
  libelle: string;
  site: SiteDTO;
}
