import {ObjectDTO} from "./object-dto";
import {AdresseDTO} from "./adresse-dto";
import {ContactDTO} from "./contact-dto";

export class ClientDTO extends ObjectDTO {

  code: string;
  libelle: string;
  actif: boolean;
  guid: string;
  adresse: AdresseDTO;
  contact1: ContactDTO;
  contact2: ContactDTO;


  constructor() {
     super();
  }

  public static getAllProperties = (): String => {
    return `
      id,
      libelle,
      code,
      actif,
      guid,
      adresse {
        id,
        ville,
        adresse1
      },
      contact1 {
        id,
        nom,
        prenom
      },
      contact2 {
        id,
        nom,
        prenom
      },
      site {
        id
      }
    `
  }
}
