import {AdresseDTO} from '../../../adresse-dto';
import {ContactDTO} from '../../../contact-dto';
import {ClientDTO} from '../../../client-dto';
import {ObjectDTO} from '../../../object-dto';
import {PointDeLivraisonDTO} from '../../../point-de-livraison-d-t-o';

export class PointFacturationDto extends ObjectDTO {
  contact1: ContactDTO;
  contact2: ContactDTO;
  client: ClientDTO;
  adresse: AdresseDTO;
  synthetique: boolean;
  delaiPaiement: number;
  pointLivraisonClientList: PointDeLivraisonDTO[];

  public static getAllProperties = (): String => {
    return `
      id,
      code,
      libelle,
      contact1 {
        id,
        nom,
        prenom,
        telephone1,
        telephone2,
        fax,
        mail
      },
      contact2 {
        id,
        nom,
        prenom,
        telephone1,
        telephone2,
        fax,
        mail
      },
      actif,
      adresse {
        id,
        nom,
        ville,
        adresse1,
        adresse2,
        adresse3,
        telephone,
        fax,
        codePostal,
        ville,
        email
      },
      client {
        id,
        libelle
      }
      synthetique,
      delaiPaiement,
      pointLivraisonClientList {
        id,
        libelle
      }
    `
  }
}
