import {HttpService} from "../technique/http.service";
import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {PointFacturationDto} from "../../dtos/facturation/clients/points-facturation/point-facturation-dto";
import {HttpClient, HttpParams} from "@angular/common/http";
import {GraphQLService} from "../technique/graphql.service";
import {Auth2Service} from "../security/auth2.service";
import {catchError, map} from "rxjs/operators";
import {UtilsService} from "../../utils/utils.service";
import {saveAs as fs_saveAs} from "file-saver";
import {FactureClientDto} from "../../dtos/facturation/clients/factures/facture-client-dto";
import {GenerateFactureClientDto} from "../../dtos/facturation/clients/factures/generate-facture-client-dto";
import {FactureClientSettingsML} from "../../dtos/facturation/clients/factures/facture-client-settings-ml";
import {FactureClientErrorsDto} from "../../dtos/facturation/clients/factures/facture-client-errors-dto";
import {LigneParametrageFacturation} from "../../models/facturation/client/ligne-parametrage-facturation";
import {ParametersGenerationLignesParametrage} from "../../models/facturation/client/parameters-generation-lignes-parametrage";
import {LigneParametrageFacturationChanges} from "../../models/facturation/client/ligne-parametrage-facturation-changes";

@Injectable({
  providedIn: 'root'
})
export class FacturationClientService {

  private subjectOpenDialogEditionPointFacturation = new Subject<PointFacturationDto>();
  openDialogEditionPointFacturation$ = this.subjectOpenDialogEditionPointFacturation.asObservable();

  private subjectPointFacturationSaved = new Subject<PointFacturationSavedSupplier>();
  pointFacturationSaved$ = this.subjectPointFacturationSaved.asObservable();

  private subjectOpenDialogDetailsFactureClient = new Subject<GenerateFactureClientSupplier>();
  openDialogDetailsFactureClient$ = this.subjectOpenDialogDetailsFactureClient.asObservable();

  private subjectFactureClientCreated = new Subject<FactureClientDto>();
  factureClientCreated$ = this.subjectFactureClientCreated.asObservable();

  private subjectFactureClientErrors = new Subject<[FactureClientErrorsDto]>();
  factureClientErrors$ = this.subjectFactureClientErrors.asObservable();

  private subjectFactureClientPrint = new Subject<FactureClientDto>();
  factureClientPrint$ = this.subjectFactureClientPrint.asObservable();

  constructor(private httpSvc: HttpService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService,
              private httpClient: HttpClient,
              private utils: UtilsService) {
  }

  announceOpenDialogEditionPointFacturation = (pointFacturation: PointFacturationDto) => {
    this.subjectOpenDialogEditionPointFacturation.next(pointFacturation);
  };

  announcePointFacturationSaved = (pointFacturation: PointFacturationDto, isUpdate: boolean = false) => {
    this.subjectPointFacturationSaved.next({isUpdate, pointFacturation} as PointFacturationSavedSupplier);
  }

  announceOpenDialogDetailsFactureClient = (generateFactureClient: GenerateFactureClientDto, factureClient: FactureClientDto) => {
    this.subjectOpenDialogDetailsFactureClient.next({generateFactureClient, factureClient});
  };

  announceFactureClientCreated = (factureClient: FactureClientDto) => {
    this.subjectFactureClientCreated.next(factureClient);
  }

  announceFactureClientErrors = (factureClientErrors: [FactureClientErrorsDto]) => {
    this.subjectFactureClientErrors.next(factureClientErrors);
  }

  printFactureClient = (printSettings: FactureClientSettingsML ): void => {
    this.httpClient.post(`dolrest/facturation-client/print-pdf`, printSettings, {responseType: 'arraybuffer'})
      .subscribe(response => {
        let blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `facture-client-${printSettings.id}-${new Date().getTime()}.pdf`);
      });
  };

  announceOpenPrintSettings = (factureClient: FactureClientDto) => {
    this.subjectFactureClientPrint.next(factureClient);
  }

  findAllPointsFacturation = (): Observable<any> => {
    return this.graphQlSvc.sendQuery(`
      {
          allPointsFacturation(filters: {
          siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}],
        }) {
          ${PointFacturationDto.getAllProperties()}
        }
      }
    `).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  savePointFacturation = (pointFacturation: PointFacturationDto) => {
    return this.httpSvc.post(`dolrest/point-facturation/save`, pointFacturation).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  deletePointFacturation = (ids: number[]) => {
    const params: HttpParams = new HttpParams().set('idsToDelete', ids.join(','));
    return this.httpSvc.delete(`dolrest/point-facturation/delete`, params).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  deleteFactureClient = (ids: number[]) => {
    const params: HttpParams = new HttpParams().set('idsToDelete', ids.join(','));
    return this.httpSvc.delete(`dolrest/facturation-client/delete`, params).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  validateFactureClient = (ids: number[]) => {
    const params: HttpParams = new HttpParams().set('idsToValidate', ids.join(','));
    return this.httpSvc.post(`dolrest/facturation-client/validate`, params).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  findAllFacturesClient = (): Observable<any> => {
    return this.graphQlSvc.sendQuery(`
      {
          allFacturesClient(filters: {
          siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}],
        }) {
          ${FactureClientDto.getAllProperties()}
        }
      }
    `).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  findAllLignesParametrage = (): Observable<any> => {
    return this.graphQlSvc.sendQuery(`
      {
          allLignesParametrageFacturation {
          ${LigneParametrageFacturation.getAllGraphQlProperties()}
        }
      }
    `).pipe(
      catchError(error => this.utils.handleError(error, true)),
      map(result => result ? result.allLignesParametrageFacturation : result)
    );
  }

  findLigneParametrageHierarchy = (): Observable<any> => {
    return this.httpSvc.get(`dolrest/facturation-client/parametrage/hierarchy`);
  }

  generateFactureClient = (generateFactureClient: GenerateFactureClientDto) => {
    return this.httpSvc.post(`dolrest/facturation-client/generate`, generateFactureClient).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  }

  saveLignesParametrage = (changes: LigneParametrageFacturationChanges): Observable<any> => {
    return this.httpSvc.post(`dolrest/facturation-client/parametrage/save`, {
      lignesToAdd: changes.toAdd,
      lignesToEdit: changes.toEdit,
      ligneIdsToDelete: changes.toDelete
    });
  }

  prepareLignesParametrageToAdd = (properties: ParametersGenerationLignesParametrage) => {
    return this.httpSvc.post(`dolrest/facturation-client/parametrage/generate-lines`, properties);
  }
}

export interface PointFacturationSavedSupplier {
  isUpdate: boolean;
  pointFacturation: PointFacturationDto;
}

export interface GenerateFactureClientSupplier {
  generateFactureClient: GenerateFactureClientDto;
  factureClient: FactureClientDto;
}
