// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[44a3209677fc6daee6315b0275df25093837db49]6.1-10936(44a3209677)-C04/12/2024-12:12:57-B04/12/2024-12:14:35' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[44a3209677fc6daee6315b0275df25093837db49]6.1-10936(44a3209677)-C04/12/2024-12:12:57-B04/12/2024-12:14:35",
  branch: "44a3209677fc6daee6315b0275df25093837db49",
  latestTag: "6.1",
  revCount: "10936",
  shortHash: "44a3209677",
  longHash: "44a3209677fc6daee6315b0275df25093837db49",
  dateCommit: "04/12/2024-12:12:57",
  dateBuild: "04/12/2024-12:14:35",
  buildType: "Ansible",
  } ;
