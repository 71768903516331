<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <form [formGroup]="form">
    <div class="dialog-line">
      <div class="label" title="Site">Site <span class="danger-color">*</span></div>
      <div class="value">
        <yo-lookup
          [data]="findAllLocalSites()"
          [selectedItem]="idSelectedSite"
          autoSelect="false"
          [placeholder]="sitePlaceholder"
          (onValueChange)="onChangeSite($event)"
          [disabled]="!canModify()"
        ></yo-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box class='inputfield' placeholder="Libellé" formControlName="libelle"
                     [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Taxe">Taxe <span class="danger-color">*</span></div>
      <div class="value">
        <dx-lookup
          [dataSource]="taxes"
          [value]="idSelectedTaxe"
          valueExpr="id"
          [displayExpr]="customDisplayExpr"
          [searchEnabled]="true"
          [placeholder]="taxePlaceholder"
          (onSelectionChanged)="onChangeTaxe($event)"
          [disabled]="!canModify()"
        >
        </dx-lookup>
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
      </div>
    </div>

    <div class="dialog-line actif-container">
      <div class="label" title="Code">Code <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box class='inputfield' placeholder="Code" formControlName="code"
                     [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>

    <div class="custom-button-container-right">
      <yo-button
        type="submit"
        iconClass="fas fa-save"
        tooltip="Enregistrer"
        buttonClass="cta-success"
        (onClick)="save()"
        [hidden]="!canModify()">
      </yo-button>
      <yo-button
        buttonClass="cta-delete"
        (onClick)="closeDialog()"
        tooltip="Fermer"
        iconClass="fas fa-times">
      </yo-button>
    </div>
  </form>

</dx-popup>
