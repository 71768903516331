import {LigneParametrageFacturation} from "./ligne-parametrage-facturation";

export class LigneParametrageFacturationChanges {
  toAdd: LigneParametrageFacturation[] = [];
  toEdit: LigneParametrageFacturation[] = [];
  toDelete: number[] = [];

  private toAddIds: number[] = [];
  private toEditIds: number[] = [];

  apply(line: LigneParametrageFacturation) {
    if (line.id > 0) {
      if (!this.toEditIds.includes(line.id)) {
        this.toEditIds.push(line.id);
        this.toEdit.push(line);
      }
    } else if (!this.toAddIds.includes(line.id)) {
      this.toAddIds.push(line.id);
      this.toAdd.push(line);
    }
  }

  delete(lineId: number) {
    if (this.toAddIds.includes(lineId)) {
      this.toAddIds = this.toAddIds.filter(item => item !== lineId);
      this.toAdd = this.toAdd.filter(item => item.id !== lineId);
    }
    if (this.toEditIds.includes(lineId)) {
      this.toEditIds = this.toEditIds.filter(item => item !== lineId);
      this.toEdit = this.toEdit.filter(item => item.id !== lineId);
    }
    this.toDelete.push(lineId);
  }

  clear() {
    this.toAdd = [];
    this.toAddIds = [];
    this.toEdit = [];
    this.toEditIds = [];
    this.toDelete = [];
  }

  revert(line: LigneParametrageFacturation) {
    if (this.toEditIds.includes(line.id)) {
      this.toEditIds = this.toEditIds.filter(item => item !== line.id);
      this.toEdit = this.toEdit.filter(item => item.id !== line.id);
    }
  }

  hasChanges() {
    return this.toAdd?.length > 0 ||
      this.toEdit?.length > 0 ||
      this.toDelete?.length > 0;
  }
}
