import {FournisseurDTO} from "../../../fournisseur-d-t-o";
import {SiteDTO} from "../../../site-dto";

export class FactureFournisseurDto {
  id: number;
  site: SiteDTO
  numero: string;
  date: Date;
  fournisseur: FournisseurDTO;

  public static getAllProperties = (): String => {
    return `id,
            numero,
            date,
            site {
              id,
              libelle
            },
            fournisseur {
              id,
              libelle
            }`;
  }
}
