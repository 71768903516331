import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {FacturationService} from "../../../../core/services/entities/facturation.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {DxDataGridComponent, DxPopupComponent} from "devextreme-angular";
import {DATAGRID_ROW_TYPES} from "../../../../core/services/technique/devextreme.service";
import {MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../../core/constants";
import {Subscription} from "rxjs";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {AvoirService} from "../../../../core/services/entities/avoir.service";
import {AvoirFournisseurDto} from "../../../../core/dtos/facturation/fournisseurs/avoirs/avoir-fournisseur-dto";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";
import {Router} from "@angular/router";
import {MotifNonConformiteDTO} from "../../../../core/dtos/motif-non-conformite-dto";
import {BonReceptionLigneMotifNonConfoDTO} from "../../../../core/dtos/bon-reception-ligne__motif-non-confo-dto";

@Component({
  selector: 'yo-dialog-details-invoice',
  templateUrl: './dialog-details-invoice.component.html',
  styleUrls: ['./dialog-details-invoice.component.scss']
})
export class DialogDetailsInvoiceComponent implements OnInit, OnDestroy {

  displayInvoice: boolean = false;

  currentInvoice: any;

  fullScreenInvoice: boolean = true;

  chooseDate: boolean = false;

  changeShippingFeesEnabled: boolean = false;

  avoirRetreived: boolean = false;

  avoirId: number = null;

  motifNonConformiteList: MotifNonConformiteDTO[] = [{id: 1, libelle: "Test1", actif: true, site: null, code: "T1"}, {
    id: 2,
    libelle: "Test2",
    actif: true,
    site: null,
    code: "T2"
  }];

  @ViewChild("gridDetails") gridDetails: DxDataGridComponent;

  @ViewChild("popup") popup: DxPopupComponent;

  subOpenDialog: Subscription;

  subDetails: Subscription;

  subGenerateAvoir: Subscription;

  subGetAvoirId: Subscription;

  subMotifs: Subscription;

  constructor(public auth2Svc: Auth2Service,
              private facturationSvc: FacturationService,
              public utilsSvc: UtilsService,
              private toastSvc: ToastService,
              private avoirSvc: AvoirService,
              private graphQlSvc: GraphQLService,
              private router: Router) {
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subDetails);
    this.utilsSvc.unsubscribe(this.subGenerateAvoir);
    this.utilsSvc.unsubscribe(this.subGetAvoirId);
  }

  ngOnInit(): void {
    this.initMotifNonConformiteList();
    this.displayInvoiceDialog();
  }

  displayInvoiceDialog = (): void => {
    this.subOpenDialog = this.facturationSvc.openDialogDetailsInvoice$
      .subscribe((currentInvoice) => {
        this.subDetails = this.facturationSvc.fetchById(currentInvoice.id)
          .subscribe((response) => {
            this.initInvoice(response.one);
            this.displayInvoice = true;
            this.refreshDataSourceDetails();
            this.getAvoirId();
          });
      });
  }

  private initInvoice = invoice => {
    this.currentInvoice = invoice;
    this.currentInvoice.rows.forEach(row => {
      row['dxMotifNonConfoList'] = row.bonReceptionLigne.bonReceptionLigneMotifNonConfoList.map(brMotif => {
        return this.motifNonConformiteList.find(motif => motif.id === brMotif.idMotifNonConformite);
      })
    })
  };

  initMotifNonConformiteList = (): void => {
    this.subMotifs = this.graphQlSvc.sendQuery(`{
      allMotifsNonConformite {
        id,
        libelle
      }
    }`).subscribe(response => {
      this.motifNonConformiteList = response.allMotifsNonConformite;
    });
  }

  getAvoirId = (): void => {
    let queryName = "allAvoirsFournisseur";
    this.subGetAvoirId = this.graphQlSvc.sendQuery(`
                {
                  ${queryName}(filters: {
                  factureIds: [${this.currentInvoice.id}]
                  }) {
                    id
                  }
                }
            `).subscribe((response: any) => {
      this.avoirRetreived = true;
      if (response[queryName] && response[queryName].length > 0 && response[queryName][0])
        this.avoirId = response[queryName][0].id;
    });
  }

  closeInvoiceDialog = (): void => {
    this.displayInvoice = false;
    this.currentInvoice = undefined;
    this.avoirRetreived = false;
    this.avoirId = null;
  }

  toggleFullScreenInvoice = (): void => {
    this.fullScreenInvoice = !this.fullScreenInvoice;
    this.resizeChilds();
  }

  joinLitigesOrMotifs = (values: any[], isMotifNonConforme: boolean): string => {
    return isMotifNonConforme ? values?.map(l => l.libelleMotifNonConformite).join(',') : values?.map(l => l.libelleLitige).join(',');
  }

  refreshDataSourceDetails = (): void => {
    this.gridDetails?.instance?.refresh();
  }

  switchChooseDate = ($event): void => {
    this.chooseDate = !this.chooseDate;
    this.currentInvoice.dateBill = new Date($event.value).getTime();
    this.facturationSvc.update(this.currentInvoice)
      .subscribe((response) => this.facturationSvc.announceRefreshInvoices());
  }

  toggleShippingFeesState = (): void => {
    this.changeShippingFeesEnabled = !this.changeShippingFeesEnabled;
  }

  onCellPreparedDetails = (event: any) => {
    this.resizeChilds();
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
      if (event.column.allowEditing === true) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }

    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === 'realQuantityInvoiced.value' && event.data?.quantityInvoicedModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === 'realUnitPrice.value' && event.data?.unitPriceModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
  };

  updateInvoice = async ($event: any, fieldName: string, idLine: number): Promise<void> => {
    let edit: boolean = true;

    if (this.avoirId)
      edit = await confirm("La facture est liée à un avoir. Confirmez vous la suppression de l'avoir afin de pouvoir modifier la facture ?", "Suppression de l'avoir lié");

    const idxLine = this.currentInvoice.rows.findIndex(row => row.id === idLine);

    if (edit) {
      let row = this.currentInvoice.rows[idxLine];
      let updateRequired = false;

      if (this.isNewNumericValue($event.previousValue, $event.value)) {
        this.updateNumericValues(fieldName, row);
        updateRequired = true;
      } else if (this.isNewValue($event.previousValue, $event.value)) {
        this.updateValues(fieldName, row, $event.value);
        updateRequired = true;
      }

      if (updateRequired) {
        let factureToSave = {...this.currentInvoice};

        factureToSave.rows = factureToSave.rows.map(row => {
          let newRow = {...row};
          delete newRow.dxMotifNonConfoList
          return newRow;
        });

        this.facturationSvc.update(factureToSave)
          .subscribe(() => {
            this.facturationSvc.fetchById(this.currentInvoice.id)
              .subscribe((response) => {
                this.initInvoice(response.one);
                this.avoirId = null;
                this.facturationSvc.announceRefreshInvoices();
              });
          });
      }
    } else {
      this.revertValues(fieldName, idxLine, $event);
    }
  }

  private revertValues(fieldName: string, idxLine, $event: any) {
    const fields = fieldName.split('.');

    let working = this.currentInvoice.rows[idxLine]
    for (let i = 0; i < fields.length - 1; i++) {
      working = working[fields[i]];
    }
    working[fields[fields.length - 1]] = $event.previousValue;
  }

  private updateValues(fieldName: string, row, value) {
    if (fieldName == 'dxMotifNonConfoList') {
      row.dxMotifNonConfoList = value;
      row.bonReceptionLigne.bonReceptionLigneMotifNonConfoList = value.map(motif => {
        let brMotif: BonReceptionLigneMotifNonConfoDTO = row.bonReceptionLigne.bonReceptionLigneMotifNonConfoList.find(motifToFind => motifToFind.idMotifNonConformite === motif.id);

        if (!brMotif) {
          brMotif = new BonReceptionLigneMotifNonConfoDTO();
          brMotif.idBonReceptionLigne = row.bonReceptionLigne.id;
          brMotif.idMotifNonConformite = motif.id;
          brMotif.libelleMotifNonConformite = motif.libelle;
        }
        return brMotif;
      });
    }
  }

  private isNewNumericValue = (previousValue, value) => {
    return typeof previousValue == 'number' && parseFloat(value) !== previousValue;
  }

  private isNewValue = (previousValue, value) => {
    return typeof previousValue != 'number' &&  previousValue != value;
  }

  private updateNumericValues = (fieldName: string, row) => {
    switch (fieldName) {
      case 'realQuantityInvoiced.value':
        row.quantityInvoicedModifiedManually = true;
        break;
      case 'realUnitPrice.value':
        row.unitPriceModifiedManually = true;
        break;
      case 'realShippingFees.value':
        this.currentInvoice.shippingFeesModifiedManually = true;
        this.toggleShippingFeesState();
        break;
    }
  };

  chooseDateInput = (): void => {
    this.chooseDate = true;
  }

  printInvoice = (): void => {
    this.facturationSvc.printInvoices([this.currentInvoice.id]);
  }

  validInvoice = async (): Promise<void> => {
    const question: string = 'Voulez-vous vraiment valider la facture ?';
    const response = await confirm(question, 'Validation de la facture');
    if (response) {
      this.facturationSvc.validInvoices([this.currentInvoice.id]).subscribe((response) => {
        this.facturationSvc.announceRefreshInvoices();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'La validation a été effectuée avec succès');
      });
    }
  }

  isMotifNonConformite = (): boolean => {
    return this.currentInvoice.rows.find(row => row.bonReceptionLigne.bonReceptionLigneMotifNonConfoList.length > 0)
  }

  generateAvoir = (): void => {
    this.subGenerateAvoir = this.avoirSvc.generateAvoir(this.currentInvoice.id).subscribe(result => {
      if (result && result.one) {
        let avoir: AvoirFournisseurDto = result.one;
        this.avoirId = avoir.id;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Génération de l'avoir ${avoir.reference}`);
        this.avoirSvc.announceAvoirGenerated(avoir);
        this.navigateToAvoir();
      }
    });
  }

  navigateToAvoir = () => {
    if (this.avoirId)
      this.router.navigate(['/factures/avoirs'], {
        queryParams: {
          'avoirId': this.avoirId
        }
      })
  }

  resizeChilds = ($event: any = null) => {
    let height: number = this.fullScreenInvoice ? window.innerHeight : this.popup.height as number;
    if ($event)
      height = $event.height
    this.gridDetails.height = height - 210;
  };

  cellTemplate = (container, options) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || []).map((element: any) => {
        return element.libelle;
      }).join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };
}

