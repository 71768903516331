import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {ElementFacturableDTO} from "../../../../core/dtos/ElementFacturableDTO";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {ElementFacturableService} from "../../../../core/services/entities/element-facturable.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {TaxeDTO} from "../../../../core/dtos/taxe-dto";
import {GraphQLService} from "../../../../core/services/technique/graphql.service";


@Component({
  selector: 'yo-elementFacturableEditionDialog',
  templateUrl: './element-facturable-edition-dialog.component.html',
  styleUrls: ['./element-facturable-edition-dialog.component.scss']
})
export class ElementFacturableEditionDialogComponent implements OnInit, OnDestroy {

  subOpenDialog: Subscription

  subGraphQL: Subscription

  taxes: TaxeDTO[] = [];

  elementFacturable: ElementFacturableDTO;

  form: FormGroup = new FormGroup({});

  dialogTitle: string = 'Modification d\'un élement facturable';

  sitePlaceholder: string = 'Selectionner le site...';
  taxePlaceholder: string = 'Selectionner la taxe...';
  displayDialog = false;
  idSelectedSite: number;
  idSelectedTaxe: number;

  constructor(private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              public elementFacturableSvc: ElementFacturableService,
              private graphQlSvc: GraphQLService,
              public utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subGraphQL);
  }

  initTaxes = (): void => {
    this.subGraphQL = this.graphQlSvc.sendQuery(`
      {
          allTaxes(filters: {
            actif: true
        }) {
              id,
              libelle,
              valeur
          }
      }
    `)
      .subscribe((res) => {
        this.taxes = res.allTaxes;
      });
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.elementFacturableSvc.openDialogEdition$
      .subscribe((element: ElementFacturableDTO) => {
        this.displayDialog = true;
        if (!element) {
          this.elementFacturable = new ElementFacturableDTO();
          this.elementFacturable.id = null;
          this.dialogTitle = 'Création d\'un élément facturable';
        } else {
          this.elementFacturable = element;
          this.dialogTitle = 'Modification d\'un élément facturable';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      let elementFacturable: ElementFacturableDTO = {...this.elementFacturable};
      elementFacturable.site = {id: this.idSelectedSite} as SiteDTO;
      elementFacturable.taxe = {id: this.idSelectedTaxe} as TaxeDTO;
      elementFacturable.libelle = this.form.controls['libelle'].value;
      elementFacturable.code = this.form.controls['code'].value;
      this.elementFacturableSvc.save(elementFacturable)
        .subscribe(response => {
          if (response && response.one) {
            const isUpdate = elementFacturable.id !== null && elementFacturable.id !== undefined && elementFacturable.id !== 0;
            this.elementFacturableSvc.announceElementFacturableSaved(response.one, isUpdate);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de l'élément facturable réalisée avec succès`);
            this.closeDialog();
          }
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.elementFacturable?.site?.libelle : 'Selectionner le site...';
    this.taxePlaceholder = !this.canModify() ? this.elementFacturable?.taxe?.libelle : 'Selectionner la taxe...';
    this.idSelectedSite = this.elementFacturable?.site?.id;
    this.idSelectedTaxe = this.elementFacturable?.taxe?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.elementFacturable?.libelle),
      code: new FormControl(this.elementFacturable?.code),
    });
  };

  onChangeSite = (data: any): void => {
    this.idSelectedSite = data;
    this.initTaxes();
  }

  canModify = () => {
    return this.elementFacturableSvc.canModify(this.elementFacturable);
  }

  customDisplayExpr = (taxe: any): string => taxe && taxe.libelle + ' - ' + taxe.valeur + '%';

  onChangeTaxe($event: any) {
    this.idSelectedTaxe = $event.selectedItem?.id;
  }

}
