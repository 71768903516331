<div class="custom-toolbar">
  <div class="custom-toolbar-subdivision-left custom-toolbar-element">
    <yo-help lang="fr" [file]="pathHelpFile" [width]="800"></yo-help>
    <yo-button
      iconClass="fa fa-plus"
      label="CRÉER"
      (onClick)="openDialogEdition(null)"
      tooltip="Créer un élément facturable">
    </yo-button>
  </div>
  <div class="custom-toolbar-subdivision-right">
    <div class="custom-button-container">
      <yo-button
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!canDelete()"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </div>
</div>

<dx-data-grid
  [dataSource]="elementsFacturables"
  keyExpr="id"
  class="data-grid"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  columnResizingMode="widget"
  #grid>
  <dxo-selection
    selectAllMode="allPages"
    showCheckBoxesMode="always"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions"
              [width]="79"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" caption="Site"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="actionsSiteTemplate"
              [calculateCellValue]="calculateSiteValue">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <dxi-column dataField="code" caption="Code"

              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <dxi-column
    dataField="value"
    caption="Taxe"
    [calculateCellValue]="calculateTaxeValue"
  >
    <ng-template dxTemplate="cell" let-data>
      {{ data.libelle + ' ' + data.valeur }}
    </ng-template>
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button
      (yoNavigation)="openDialogEdition(cell.row.data)"
      [yoTooltipShowDelay]="500"
      [yoSmall]="true"
      yoTextAlign="center"
      [yoIconClass]="elementFacturableSvc.canModify(cell.row.data)? 'fa fa-edit' : 'fas fa-eye'"
      [yoTooltip]="elementFacturableSvc.canModify(cell.row.data)? 'Modifier l\'allergène' : 'Voir l\'allergène'">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'actionsSiteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} éléments facturables">
  </dxo-pager>

</dx-data-grid>

<dx-popup
  [showTitle]="true"
  title="Lignes n'ayant pas été supprimées"
  [(visible)]="displayDeletePopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Les éléments non présents dans cette liste ont bien été supprimés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="infosLine" caption="Ligne non supprimée" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>

<yo-elementFacturableEditionDialog></yo-elementFacturableEditionDialog>
