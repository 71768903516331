import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {CoreModule} from "../core/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FacturesRoutingModule} from "./factures-routing.module";
import {FacturesFournisseursComponent} from "./fournisseurs/factures/factures-fournisseurs.component";
import {FacturesRootComponent} from "./factures-root.component";
import {
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule, DxListModule, DxLookupModule, DxNumberBoxModule,
    DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule,
    DxTagBoxModule, DxTextAreaModule,
    DxTextBoxModule,
    DxToolbarModule, DxTreeViewModule
} from "devextreme-angular";

import {
  DialogJoinBonReceptionComponent
} from "./fournisseurs/factures/dialog-join-bon-reception/dialog-join-bon-reception.component";
import {DialogDetailsInvoiceComponent} from "./fournisseurs/factures/dialog-details-invoice/dialog-details-invoice.component";
import {AvoirFournisseurComponent} from "./fournisseurs/avoirs/avoir-fournisseur.component";
import {AvoirFournisseurDetailsDialogComponent} from "./fournisseurs/avoirs/dialog-edition/avoir-fournisseur-details-dialog.component";
import {PointFacturationComponent} from "./clients/points-facturation/point-facturation.component";
import {PointFacturationEditionDialogComponent} from "./clients/points-facturation/edition-dialog/point-facturation-edition-dialog";
import {FacturesClientsComponent} from "./clients/factures/factures-clients.component";
import {FactureClientDetailsDialogComponent} from "./clients/factures/details-dialog-facture-client/facture-client-details-dialog.component";
import {
  PrintFactureSettingsComponent
} from "./clients/factures/details-dialog-facture-client/print-dialog-settings/print-facture-settings.component";
import  {FactureClientErrorsComponent} from "./clients/factures/errors/facture-client-errors.component";
import {FacturationClientParametrageComponent} from "./clients/parametrage/facturation-client-parametrage.component";

@NgModule({
    imports: [
        CommonModule,
        FacturesRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        DxDataGridModule,
        DxToolbarModule,

        DxTagBoxModule,
        DxPopupModule,
        DxDateBoxModule,
        DxTextBoxModule,
        DxScrollViewModule,
        DxLookupModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        DxButtonGroupModule,
        DxListModule,
        DxRadioGroupModule,
        DxSelectBoxModule,
        DxTreeViewModule,
    ],
  declarations: [
    FacturesRootComponent,
    FacturesFournisseursComponent,
    DialogJoinBonReceptionComponent,
    DialogDetailsInvoiceComponent,
    AvoirFournisseurComponent,
    AvoirFournisseurDetailsDialogComponent,
    PointFacturationComponent,
    PointFacturationEditionDialogComponent,
    FacturesClientsComponent,
    FactureClientDetailsDialogComponent,
    PrintFactureSettingsComponent,
    FactureClientErrorsComponent,
    FacturationClientParametrageComponent
  ]
})
export class FacturesModule { }
